main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*
 * .calculator is a flexbox containing the following children:
 *   - .header-row, .display-spacer, .display, .button-grid
 *
 * .display-spacer will have `flex-grow: 1` set under the right
 * conditions to make the calculator layout fill the screen vertically,
 * and will be height 0 otherwise to layout the other items centred on screen.
 */
.calculator {
  width: 100%;
  height: 100%;
  --padding-x: max(env(safe-area-inset-left, 0px), env(safe-area-inset-right, 0px));
  max-width: calc(var(--max-width) + 2 * var(--padding-x));
  padding: env(safe-area-inset-top, 0px) var(--padding-x) env(safe-area-inset-bottom, 0px);
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  /* The layout breaks on small mobile landscape displays. The min-height makes
  it break in a less ugly way. */
  min-height: 320px;
}

.header-row {
  padding: 20px;
  min-height: 44px;
}

.display-spacer {
  flex-basis: 0;
}

/* Layout fills entire screen height when aspect ratio is portrait. Portrait
screens are more likely to be used with touch, so we want the buttons at the
bottom. */
/* stylelint-disable-next-line media-feature-name-no-unknown */
@media screen and (max-aspect-ratio: 1/1) {
  .display-spacer {
    flex-grow: 1;
  }
}

.display {
  width: 100%;
  height: 130px;
  @screen tablet {
    height: 154px;
  }
  flex-shrink: 0;
}

.button-grid {
  display: grid;
  width: 100%;
  gap: 1px;
  grid-template-columns: repeat(4, 1fr);
  flex-grow: 1;
  max-height: 400px;
  user-select: none;
}

button > * {
  margin: auto;
}
