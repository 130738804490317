/* Note that dialog::backdrop does not inherit from anything
(https://stackoverflow.com/a/63322762), so we set all variables on :root and
::backdrop. */

:root,
::backdrop {
  --max-width: 440px;

  --input-font-size: 24px;
  --input-font-weight: 200;
  --input-line-height: 1.5;
  /* The JIT result's font size is the input-font-size scaled by
  Calculator.JIT_SCALE, so it doesn't get its own variable. */
  --jit-font-weight: 400;
  --jit-offset: 63px; /* offset between center of input and center of JIT result */
  @screen tablet {
    --input-font-size: 24px;
    --jit-offset: 75px;
  }
  --jit-opacity: 0.5;

  --button-font-size: 1.5rem;

  --root-bg: #f2f8ff;
  --root-image: url("./background-light.svg");

  --text-fg: #333333;
  --text-fg-jit: #808080;
  --text-bg: #ffffff;

  --alert-bg: #cc1f1f;
  --alert-fg: #ffffff;

  --icon-fg: #808080;

  --button-fg: #333333;
  --button-fg-red: #cc1f1f;
  --button-fg-equals: #ffffff;
  --button-bg-number: #f1f1f1;
  --button-bg-number-hover: #e5e5e5;
  --button-bg-number-active: #cccccc;
  --button-bg-tool: #eaeef3;
  --button-bg-tool-hover: #d3dbe4;
  --button-bg-tool-active: #b3bdca;
  --button-bg-operator: #e3f0ff;
  --button-bg-operator-hover: #d2e0f1;
  --button-bg-operator-active: #bbcadb;
  --button-bg-equals: #7d8cb9;
  --button-bg-equals-hover: #6173a9;
  --button-bg-equals-active: #48588a;

  --dialog-bg: hsla(217, 39%, 34%, 1);
  --dialog-bg-section: rgba(0, 0, 0, 0.2);
  --dialog-fg: rgba(255, 255, 255, 0.8);
  --dialog-fg-dim: rgba(255, 255, 255, 0.5);
  --dialog-backdrop: transparent;
}

@media (prefers-color-scheme: dark) {
  :root,
  ::backdrop {
    --root-bg: #070a12;
    --root-image: url("./background-dark.svg");

    --text-fg: #ffffff;
    --text-fg-jit: #808080;
    --text-bg: #000000;

    --alert-bg: #cc1f1f;
    --alert-fg: #ffffff;

    --icon-fg: #808080;

    --button-fg: #ffffff;
    --button-fg-red: #ff7272;
    --button-fg-equals: #ffffff;
    --button-bg-number: #111111;
    --button-bg-number-hover: #222222;
    --button-bg-number-active: #333333;
    --button-bg-tool: #161922;
    --button-bg-tool-hover: #202534;
    --button-bg-tool-active: #2b3146;
    --button-bg-operator: #12182b;
    --button-bg-operator-hover: #202534;
    --button-bg-operator-active: #2b3146;
    --button-bg-equals: #48588a;
    --button-bg-equals-hover: #6173a9;
    --button-bg-equals-active: #7d8cb9;

    --dialog-bg: #131d2d;
    --dialog-bg-section: rgba(0, 0, 0, 0.2);
    --dialog-fg: rgba(255, 255, 255, 0.8);
    --dialog-fg-dim: rgba(255, 255, 255, 0.5);
    --dialog-backdrop: transparent;
  }
}

@screen tablet {
  :root,
  ::backdrop {
    --dialog-backdrop: hsla(225, 30%, 61%, 0.5);
  }

  @media (prefers-color-scheme: dark) {
    :root,
    ::backdrop {
      --dialog-backdrop: hsla(225, 0%, 0%, 0.8);
    }
  }
}

main {
  background: transparent;
  overflow: hidden;
}

main::before {
  content: "";
  z-index: -1;
  position: fixed;
  top: -3000px;
  bottom: 0;
  left: 0;
  right: -3000px;
  background-color: var(--root-bg);
  background-image: var(--root-image);
  background-repeat: repeat;
  background-size: 100px 100px;
  transform: rotate(30deg);
  transform-origin: top left;
}

.calculator > * {
  /* We colour the direct children of div.calculator individually
   * instead of their parent, so the background colour doesn't
   * extend to the screen edge even with a centred layout. */
  background: var(--text-bg);
}

.help-icon {
  fill: var(--icon-fg);
}

.alert {
  background: var(--alert-bg);
  color: var(--alert-fg);
  fill: var(--alert-fg);
}

.display {
  color: var(--text-fg);
}

button {
  font-size: var(--button-font-size);
}

.calc-input,
.just-in-time-result {
  background: transparent;
  /* Both input and JIT result must have the same line-height and font-size for
  the animation to line up. Note that the JIT result starts off with the same
  font size as the input, but will be scaled in JavaScript by
  Calculator.JIT_SCALE. */
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  /* Note that the just-in-time result's grayed-out color is caused by
  --jit-opacity, so we give it the same text color as the input. */
  color: var(--text-fg);
}

.calc-input {
  font-weight: var(--input-font-weight);
}

.just-in-time-result {
  font-weight: var(--jit-font-weight);
  opacity: var(--jit-opacity);
}

/* -- Styling for the various button types -- */

.b-number {
  background: var(--button-bg-number);
  color: var(--button-fg);
  fill: var(--button-fg);

  &:hover {
    background: var(--button-bg-number-hover);
  }

  &:active {
    background: var(--button-bg-number-active);
  }
}

.b-tool {
  background: var(--button-bg-tool);
  color: var(--button-fg);
  fill: var(--button-fg);

  &:hover {
    background: var(--button-bg-tool-hover);
  }

  &:active {
    background: var(--button-bg-tool-active);
  }
}

.b-operator {
  background: var(--button-bg-operator);
  color: var(--button-fg);
  fill: var(--button-fg);

  &:hover {
    background: var(--button-bg-operator-hover);
  }

  &:active {
    background: var(--button-bg-operator-active);
  }
}

.b-equals {
  background: var(--button-bg-equals);
  color: var(--button-fg-equals);
  fill: var(--button-fg-equals);

  &:hover {
    background: var(--button-bg-equals-hover);
  }

  &:active {
    background: var(--button-bg-equals-active);
  }
}

.b-ac {
  background: var(--button-bg-tool);
  color: var(--button-fg-red);
  fill: var(--button-fg-red);

  &:hover {
    background: var(--button-bg-tool-hover);
  }

  &:active {
    background: var(--button-bg-tool-active);
  }
}
