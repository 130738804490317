@import "tailwind.css";
@import "elevation.css";
@import "theme.css";
@import "layout.css";
@import "dialog.css";

html {
  /* Make sure 1rem is always 16px, as tailwindcss uses rem extensively. */
  font-size: 16px;

  /* Disable WebKit's tap highlight behavior when a click handler is installed. */
  -webkit-tap-highlight-color: transparent;
}

/* Disable scrolling and scaling. Note that iOS doesn't respect user-scalable=no
(https://stackoverflow.com/a/68278302). This makes button taps much more
forgiving to sliding the finger on iOS. Note that the dialog has its own
`overflow: auto` element, which isn't affected by this. */
body,
dialog::backdrop {
  touch-action: none;
}
/* Disable scaling everywhere. This prevents the following scenario: User opens
help, zooms into help, then closes the dialog while still zoomed in. Now they're
on the calculator page zoomed in, but cannot zoom out again.

Note that dialog::backdrop is not covered by `*`, so if you remove it above,
make sure to add it here. */
* {
  touch-action: pan-x pan-y;
}
