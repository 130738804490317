/* Chris Coyer's Scroll Shadow */
/* https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/ */
.scroll-shadows {
  --shadow: rgba(0, 0, 0, 0.5);

  background:
    /* Shadow Cover TOP */ linear-gradient(var(--dialog-bg) 30%, transparent) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(transparent, var(--dialog-bg) 70%) center bottom,
    /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, var(--shadow), rgba(0, 0, 0, 0)) center top,
    /* Shadow BOTTOM */ radial-gradient(farthest-side at 50% 100%, var(--shadow), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

:root,
::backdrop {
  --dialog-margin: 16px;
}

@screen tablet {
  :root,
  ::backdrop {
    --dialog-margin: 60px;
  }
}

dialog {
  width: 800px;
  --adjusted-x-margin: max(var(--dialog-margin), env(safe-area-inset-left, 0px), env(safe-area-inset-right, 0px));
  --adjusted-y-margin: max(var(--dialog-margin), env(safe-area-inset-top, 0px), env(safe-area-inset-bottom, 0px));
  max-width: calc(100% - 2 * var(--adjusted-x-margin));
  height: calc(100% - 2 * var(--adjusted-y-margin));
  max-height: calc(100% - 2 * var(--adjusted-y-margin));
  border: none;
  @apply elevation-3;
  @screen tablet {
    @apply elevation-5;
  }

  background: var(--dialog-bg);
  color: var(--dialog-fg);
  @apply text-sm;
  /* Our only dialog has a tab group, whose panels have overflow: auto */
  overflow: hidden;

  &::backdrop {
    background: var(--dialog-backdrop);
  }

  /* Set tab panels to full height of dialog. */
  .dialog-content > .tabs {
    height: 100%;

    & > div:last-child {
      flex-grow: 1;
      display: flow-root;
      @apply scroll-shadows;

      & > [role="tabpanel"] {
        overflow-y: auto;
        /* Stop mobile Safari from going into pull-to-refresh mode, which seems
        to sometimes break scrolling. */
        overscroll-behavior: contain;
        min-height: 100%;
        height: 1px; /* element has height; https://stackoverflow.com/a/21836870 */
        padding: 0;
      }
    }
  }

  /* Tab bar styling */
  .dialog-content > .tabs > div[role="tablist"] {
    padding-left: 20px;
    padding-right: 20px;

    & > button {
      padding: 8px;
      padding-top: 13px;

      &[aria-selected="true"] {
        color: var(--dialog-fg);
        border-color: var(--dialog-fg);
      }
    }
  }

  .dim {
    color: var(--dialog-fg-dim);
  }

  .section-body {
    background: var(--dialog-bg-section);
    padding: 14px;
    border-radius: 10px;
  }

  .highlight {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 900;
    letter-spacing: 0.02em;
    margin-bottom: 40px;
    /* The color should move into theme.css if we don't end up getting rid of
    the highlight. */
    color: #fff;
    fill: #fff;
  }

  .large {
    font-size: 18px;
    line-height: 1.2;
    @screen tablet {
      line-height: 1.5;
    }
    font-weight: 400;
  }

  h1,
  .close-button {
    /* TODO @apply text-base on mobile */
    @apply text-xl;
    font-weight: 600;
  }

  h2 {
    margin-top: 20px;
    font-weight: 900;
    margin-bottom: 8px;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 11px;
    margin: -11px 0;

    th {
      text-align: left;
      font-size: 10px;
      @screen tablet {
        font-size: 12px;
      }
      font-weight: 600;
      color: var(--dialog-fg-dim);
    }

    tbody th {
      font-size: 10px;
    }

    td {
      font-size: 12px;
      @screen tablet {
        font-size: 14px;
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .usage-table {
    padding: 11px 9px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }

    td,
    th {
      vertical-align: top;
      line-height: 1.25rem;
    }

    td:last-child {
      font-size: 12px;

      @screen tablet {
        font-size: 14px;
      }
    }

    table {
      border-spacing: 0 7px;
    }

    .aligned-equal {
      display: inline-block;
      width: 0;
      position: relative;
      right: 1rem;
    }
  }
}
